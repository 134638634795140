.cometchat-transfer-ownership {
    display: flex;
    width: 420px;
    height: 600px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    background: var(--cometchat-background-color-01);
    border-radius: var(--cometchat-radius-5, 20px);
}

.cometchat-transfer-ownership-dialog__backdrop .cometchat-confirm-dialog .cometchat-confirm-dialog__button-group-submit .cometchat-button {
    background: var(--cometchat-primary-color);
}

.cometchat-transfer-ownership-dialog__backdrop .cometchat-confirm-dialog__icon-wrapper {
    display: none;
}

.cometchat-transfer-ownership-dialog__backdrop .cometchat-confirm-dialog__button-group-submit {
    background: var(--cometchat-primary-button-background);
}

.cometchat-transfer-ownership>.cometchat {
    overflow: hidden;
    border-radius: inherit;
}

.cometchat-transfer-ownership__buttons-wrapper .cometchat-button {
    width: 190px;
    font: var(--cometchat-font-button-medium);
}

.cometchat-transfer-ownership__buttons-wrapper>.cometchat {
    width: fit-content;
}

.cometchat-transfer-ownership__buttons-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cometchat-transfer-ownership__cancel-button .cometchat-button {
    background: var(--cometchat-background-color-01, #FFF);
    border: 1px solid var(--cometchat-border-color-dark);
}

.cometchat-transfer-ownership__cancel-button .cometchat-button .cometchat-button__text {
    color: var(--cometchat-neutral-color-900);
}

.cometchat-transfer-ownership .cometchat-list__header-title {
    justify-content: center;
}

.cometchat-transfer-ownership .cometchat-group-members .cometchat-list-item:hover {
    background: inherit;
    cursor: default;
}

.cometchat-transfer-ownership__transfer-button-disabled .cometchat-button {
    background: var(--cometchat-background-color-04, #E8E8E8);
    color: var(--cometchat-static-white, #FFF);
    pointer-events: none;
}

.cometchat-transfer-ownership .cometchat-list-item__tail-view .cometchat-radiobutton__label {
    flex-direction: row-reverse;
}

.cometchat-transfer-ownership .cometchat-list-item .cometchat-list-item__body {
    align-items: center;
}

.cometchat-transfer-ownership .cometchat-list-item .cometchat-list-item__body-subtitle {
    display: none;
}

.cometchat-transfer-ownership_error-view {
    margin-top: 4px;
    color: var(--cometchat-error-color, #F44649);
    font: var(--cometchat-font-body-regular);
    font-style: normal;
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: rgba(244, 70, 73, 0.10);

}

.cometchat-transfer-ownership__buttons {
    display: flex;
    justify-content: center;
    padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
}

@media (max-width: 768px) {
    .cometchat-transfer-ownership {
        height: 100vh;
        width: 100vw;
        border-radius: 0px;
    }

    .cometchat-transfer-ownership .cometchat-list__header-title {
        justify-content: flex-start;
    }
}