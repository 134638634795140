.cometchat-user-details__header {
    display: flex;
    min-height: 65px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    align-self: stretch;
    border-bottom: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-user-details__header-icon {
    -webkit-mask: url("../../assets/close.svg") center center no-repeat;
    background: var(--cometchat-icon-color-primary, #141414);
    -webkit-mask-size: contain;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.cometchat-user-details__header-icon:hover {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-user-details__header-text {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading3-bold, 700 20px Roboto);
}

.cometchat-user-details__content {
    display: flex;
    padding-top: 40px;
    height: calc(100vh - 64px);
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-5, 20px);
    flex-shrink: 0;
    align-self: stretch;
}

.cometchat-user-details__content-avatar {
    display: flex;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-max, 1500px);
}

.cometchat-user-details__content-avatar .cometchat-avatar,
.cometchat-user-details__content-avatar .cometchat-avatar__image,
.cometchat-user-details__content-avatar .cometchat-avatar__text {
    width: 120px;
    height: 120px;
}

.cometchat-user-details__content-avatar .cometchat-avatar__text {
    font: 700 64px/76.8px var(--cometchat-font-family);
    height: fit-content;
    width: fit-content;
    font-style: normal;
}

.cometchat-user-details__content-title {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading3-medium, 500 20px Roboto);
}

.cometchat-user-details__content-description {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.cometchat-user-details__content-action {
    width: 100%;
    border-top: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    border-bottom: 1px solid var(--cometchat-border-color-light, #F5F5F5);
}

.cometchat-user-details__content-action-item {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-5, 20px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    align-self: stretch;
    cursor: pointer;
}

.cometchat-user-details__content-action-item-icon {
    background: var(--cometchat-error-color, #F44649);
    -webkit-mask-size: contain;
    width: 24px;
    height: 24px;
}

.cometchat-user-details__content-action-item-text {
    color: var(--cometchat-error-color, #F44649);
    font: var(--cometchat-font-heading4-regular, 400 16px Roboto);
}