.cometchat-login__container {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-8, 32px);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--cometchat-background-color-04, #E8E8E8);
}

.cometchat-login__content {
  display: flex;
  width: 400px;
  padding: var(--cometchat-padding-7, 28px) var(--cometchat-padding-10, 40px);
  flex-direction: column;
  gap: var(--cometchat-padding-7, 28px);
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-5, 20px);
  border: 1px solid var(--cometchat-border-color-default, #e8e8e8);
  background: var(--cometchat-background-color-01, #fff);
}


.cometchat-login__header {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-5, 20px);
  align-self: stretch;
}

.cometchat-login__logo>img {
  height: 30px;
}

.cometchat-login__title {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-heading1-bold);
  font-style: normal;
}


.cometchat-login__sample-users {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}


.cometchat-login__sample-users-title {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-body-medium);
  font-style: normal;
}


.cometchat-login__user-list {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.cometchat-login__user {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  max-height: 140px;
  min-height: 140px;
  height: fit-content;

  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: var(--cometchat-radius-2, 8px);
  border: 1px solid var(--cometchat-border-color-light, #f5f5f5);

  background: var(--cometchat-background-color-01, #fff);
  cursor: pointer;

  position: relative;
}

.cometchat-login__user-selected {
  border: 1px solid var(--cometchat-border-color-highlight, #6852d6);
}


.cometchat-login__user-selection-indicator {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2.5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1.333px;
  top: 0;
  border-radius: 0px var(--cometchat-radius-2, 8px);
  background: var(--cometchat-icon-color-highlight, #6852d6);
  right: -1px;
}

.cometchat-login__user-selection-checked {
  -webkit-mask: url("../../assets/right-tick-icon.svg") center center no-repeat;
  mask: url("../../assets/right-tick-icon.svg") center center no-repeat;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  border: none;
  align-self: center;
  background: white;
}

.cometchat-login__user-avatar {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--cometchat-radius-Max, 1000px);
}

.cometchat-login__user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--cometchat-padding-1, 4px);
  align-self: stretch;
}

.cometchat-login__user-name {
  overflow: hidden;
  color: var(--cometchat-text-color-primary, #141414);
  text-align: center;
  text-overflow: ellipsis;
  font: var(--cometchat-font-body-medium);
  font-style: normal;
}

.cometchat-login__user-uid {
  overflow: hidden;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  text-overflow: ellipsis;
  font: var(--cometchat-font-caption1-regular);
  font-style: normal;
}

.cometchat-login__divider-section {
  display: flex;
  align-items: center;
  gap: 13px;
  color: var(--cometchat-text-color-tertiary, #a1a1a1);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-login__divider {
  width: 100%;
  height: 1px;
  background: var(--cometchat-border-color-default, #e8e8e8);
  padding: 0;
}

.cometchat-login__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--cometchat-padding-5, 20px);
  align-self: stretch;
}

.cometchat-login__input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  gap: 4px;
}

.cometchat-login__input-label {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-caption1-medium);
  font-style: normal;
}

.cometchat-login__custom-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--cometchat-padding-5, 20px);
  align-self: stretch;
}


.cometchat-login__input {
  width: 100%;
  display: flex;
  height: 36px;
  padding: var(--cometchat-padding-2, 8px);
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  align-self: stretch;
  border-radius: var(--cometchat-radius-2, 8px);
  border: 1px solid var(--cometchat-border-color-light, #f5f5f5);
  background: var(--cometchat-background-color-02, #fafafa);
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  border: none;
}

.cometchat-login__input:focus-visible {
  outline: none;
}

.cometchat-login_input-Wrapper-text {
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-caption1-medium);
  font-style: normal;
}


.cometchat-login__submit-button {
  width: 100%;
  display: flex;
  height: 40px;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-2, 8px);
  align-self: stretch;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-primary-button-background, #6852d6);
  color: var(--cometchat-static-white, #fff);
  font: var(--cometchat-font-button-medium);
  font-style: normal;
  border: none;
  cursor: pointer;
}

.cometchat-login__signup-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  gap: 4px;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-login__signup-link {
  color: var(--cometchat-text-color-highlight, #6852d6);
  font: var(--cometchat-font-body-medium);
  font-style: normal;
  cursor: pointer;
}

.cometchat-login__note {
  width: 100%;
  color: var(--cometchat-text-color-secondary);
  font: var(--cometchat-font-body-regular);
}

.cometchat-login__note-link {
  color: var(--cometchat-text-color-highlight, #6852d6);
  cursor: pointer;
}