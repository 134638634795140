.cometchat-new-chat-view {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--cometchat-background-color-01);
}

.cometchat-new-chat-view__header {
    display: flex;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    min-height: 65px;
    justify-content: flex-start;
    background: inherit;
    border-bottom: 1px solid var(--cometchat-border-color-light);
}

.cometchat-new-chat-view__header-title {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading2-bold);
}

.cometchat-new-chat-view__header .cometchat-button {
    height: fit-content;
    width: fit-content;
    background: transparent;
    padding: 0px;
}

.cometchat-new-chat-view__header .cometchat-button .cometchat-button__icon {
    height: 24px;
    width: 24px;
    background: var(--cometchat-icon-color-primary);
}

.cometchat-new-chat-view__header .cometchat-button__icon:hover {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-new-chat-view__tabs {
    display: flex;
    padding: 1px;
    align-items: flex-start;
    align-self: stretch;
    border-right: 1px solid var(--cometchat-border-color-light);
    background: var(--cometchat-background-color-03);
    border-radius: var(--cometchat-radius-max, 1000px);
    margin: var(--cometchat-margin-3, 12px) var(--cometchat-margin-4, 16px) var(--cometchat-margin-2, 8px) var(--cometchat-margin-4, 16px);
}

.cometchat-new-chat-view__tabs-tab {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    flex: 1 0 0;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: inherit;
    color: var(--cometchat-text-color-secondary);
    font: var(--cometchat-font-heading4-medium);
    cursor: pointer;
}

.cometchat-new-chat-view__tabs-tab-active {
    border-right: 1px solid var(--cometchat-border-color-light);
    background: var(--cometchat-background-color-01);
    color: var(--cometchat-text-color-highlight);

}

.cometchat-new-chat-view .cometchat-list__header-search-bar {
    padding-top: var(--cometchat-padding-2);
}

@media (max-width: 768px) {
    .cometchat-new-chat-view {
        width: 100vw;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 4;
    }
}