.cometchat-call-log-recordings {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cometchat-call-log-recordings__download {
    -webkit-mask: url("../../assets/downloadRecording.svg");
    background: var(--cometchat-icon-color-highlight);
    -webkit-mask-size: contain;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.cometchat-call-log-recordings__subtitle .cometchat-date {
    padding: 0px;
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
}

.cometchat-call-log-recordings__empty-state {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    flex-shrink: 0;
    align-self: stretch;
}

.cometchat-call-log-recordings__empty-state-icon {
    -webkit-mask: url("../../assets/callRecordingEmpty.svg") center center no-repeat;
    background: var(--cometchat-icon-color-tertiary);
    -webkit-mask-size: contain;
    height: 60px;
    width: 60px;
}

.cometchat-call-log-recordings__empty-state-text {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading4-medium);
}