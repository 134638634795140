.cometchat-threaded-message {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.cometchat-threaded-message .cometchat-threaded-message-preview__header {
    height: 65px;
    border-left: none;
}

.cometchat-threaded-message-list .cometchat-message-bubble .cometchat-text-bubble {
    max-width: 280px;
}

.cometchat-composer-wrapper .cometchat-mention-list {
    left: 8px;
}
.cometchat-threaded-message  .message-composer-blocked{
    display: flex;
width: 100%;
height: 48px;
padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-5, 20px);
flex-direction: column;
justify-content: center;
align-items: center;
gap: var(--cometchat-padding-1, 4px);
flex-shrink: 0;
background: var(--cometchat-background-color-04, #E8E8E8);
}
.cometchat-threaded-message  .message-composer-blocked__text{
    color: var(--cometchat-text-color-secondary, #727272);
font:var(--cometchat-font-body-regular);
}
.cometchat-threaded-message  .message-composer-blocked__text > a{
    text-decoration: underline;
    text-decoration-color: var(--cometchat-primary-color);
    cursor: pointer;
    color: var(--cometchat-primary-color);;
}
.cometchat-threaded-message-list {
    overflow: hidden;
    height: 100%;
}

.cometchat-threaded-message-list .cometchat-message-list {
    background: var(--cometchat-background-color-01, #FFFFFF);
}

.cometchat-threaded-message-composer {
    padding: 8px;
}

.side-component-wrapper>.cometchat,
.side-component-wrapper .cometchat-threaded-message-preview {
    width: 100%;
}

.cometchat-threaded-message-list .cometchat-list__header {
    border-bottom: none;
}

.cometchat-threaded-message .cometchat-action-sheet {
    height: fit-content;
}

.cometchat-threaded-message .cometchat-message-composer__secondary-button-view-attachment-button .cometchat-popover__content {
    height: 226px;
}