.cometchat-alert-popup__backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cometchat-alert-popup-wrapper {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    border-radius: var(--cometchat-radius-4, 16px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-alert-popup {
    display: flex;
    padding: var(--cometchat-padding-6, 24px);
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--cometchat-radius-4, 16px);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-alert-popup__icon-wrapper {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-background-color-02, #FAFAFA);
}

.cometchat-alert-popup__icon {
    width: 48px;
    height: 48px;
    -webkit-mask: url("../../assets/block.svg") center center no-repeat;
    background: var(--cometchat-error-color);
    -webkit-mask-size: contain;
}

.cometchat-alert-popup__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}

.cometchat-alert-popup__text-title {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading2-medium);
}

.cometchat-alert-popup__text-subtitle {
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-regular);
}

.cometchat-alert-popup__button-wrapper {
    display: flex;
    padding: var(--cometchat-padding-3, 12px) 0px;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
}

.cometchat-alert-popup__button-wrapper .cometchat-button {
    display: flex;
    height: 40px;
    width: 100%;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
    background: transparent;
}

.cometchat-alert-popup__button-wrapper .cometchat-button__text {
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-body-medium);
}

.cometchat-alert-popup__button-wrapper>.cometchat {
    width: 100%;
}

@media (max-width: 768px) {
    .cometchat-alert-popup-wrapper {
        width: 320px;
    }
}