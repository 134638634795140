.cometchat-add-members .cometchat-list__header .cometchat-list__header-title {
    margin-left: 32px;
    overflow: hidden;
    color: var(--cometchat-text-color-primary);
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading2-bold);
}

.cometchat-add-members .cometchat-add-members__back-button .cometchat-button {
    height: 24px;
    width: 24px;
    padding: 0;
    background: transparent;
}

.cometchat-add-members .cometchat-add-members__back-button {
    position: absolute;
    top: 20px;
    left: 16px;
    height: 24px;
    width: 24px;
    overflow: hidden;
}

.cometchat-add-members .cometchat-add-members__back-button .cometchat-button .cometchat-button__icon {
    background: var(--cometchat-icon-color-primary);
}

.cometchat-add-members__add-btn-wrapper .cometchat-button {
    width: 100%;
    height: 40px;
}

.cometchat-add-members .cometchat-list-item__tail-view {
    height: 26px;
    width: 26px;
}

.cometchat-add-members {
    width: 100%;
    height: 100%;
    background: var(--cometchat-background-color-01);
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
    overflow: hidden;
}

.cometchat-add-members__add-btn-wrapper {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cometchat-add-members__add-btn-wrapper>.cometchat {
    width: 100%;
}
.cometchat-add-members_error-view{
    margin-top: 4px;
    color: var(--cometchat-error-color, #F44649);
    font: var(--cometchat-font-body-regular);
    font-style: normal;
    display: flex;
    padding: var(--cometchat-padding-1, 4px) var(--cometchat-padding-2, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: rgba(244, 70, 73, 0.10);

}
.cometchat-add-members__add-btn-wrapper-disabled .cometchat-button{
    background: var(--cometchat-background-color-04, #E8E8E8);
    color: var(--cometchat-static-white, #FFF);
    pointer-events: none;
}