.cometchat-credentials__page {
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-8, 32px);
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--cometchat-background-color-04, #E8E8E8);
}

.cometchat-credentials__container {
  display: flex;
  width: 400px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid var(--cometchat-border-color-default, #e8e8e8);
  background: var(--cometchat-background-color-01, #fff);
}

.cometchat-credentials__logo>img {
  height: 24px;
}

.cometchat-credentials__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--cometchat-padding-4, 16px);
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-heading1-bold);
  font-style: normal;
}

.cometchat-credentials__form-group {
  width: 100%;
  text-align: left;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.cometchat-credentials__form-label {
  width: 100%;
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-caption1-medium);
  font-style: normal;
}

.cometchat-credentials__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.cometchat-credentials__form-input {
  width: 100%;
  display: flex;
  height: 36px;
  padding: var(--cometchat-padding-2, 8px);
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  align-self: stretch;
  border-radius: var(--cometchat-radius-2, 8px);
  border: 1px solid var(--cometchat-border-color-light, #f5f5f5);
  background: var(--cometchat-background-color-02, #fafafa);
  color: var(--cometchat-text-color-primary, #141414);
  font: var(--cometchat-font-body-regular);
  font-style: normal;
}

.cometchat-credentials__button {
  display: flex;
  height: 40px;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-2, 8px);
  align-self: stretch;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-primary-button-background, #6852d6);
  border: none;
  color: var(--cometchat-static-white, #fff);
  font: var(--cometchat-font-button-medium);
  font-style: normal;
  cursor: pointer;
}

.cometchat-credentials__sign-in-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-body-regular);
  font-style: normal;
  gap: var(--cometchat-padding-1, 4px)
}

.cometchat-credentials__sign-in-link {
  color: var(--cometchat-text-color-highlight, #6852d6);
  font: var(--cometchat-font-body-medium);
  font-style: normal;
  cursor: pointer;
}

.cometchat-credentials__form-checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-credentials__checkbox-label {
  color: var(--cometchat-text-color-secondary, #727272);
  font: var(--cometchat-font-caption1-regular);
  font-style: normal;
}

.cometchat-credentials__region-wrapper {
  display: flex;
  gap: var(--cometchat-padding-2, 8px);
}

.cometchat-credentials__region {
  display: flex;
  height: 40px;
  padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--cometchat-padding-1, 4px);
  flex: 1 0 0;
  border-radius: var(--cometchat-radius-2, 8px);
  background: var(--cometchat-extended-primary-color-50, #f9f8fd);
}

.cometchat-credentials__region-selected {
  border: 1px solid var(--cometchat-border-color-highlight, #6852d6);
}

.cometchat-credentials__region-text {
  color: var(--cometchat-text-color-secondary, #727272);
  text-align: center;
  font: var(--cometchat-font-button-medium);
  font-style: normal;
}