div#log-out {
    display: none;
}

.centered-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Optional background overlay */
    z-index: 9999;
    /* Ensure it's on top of everything else */
    text-align: center;
}

.oval-animation svg {
    stroke: var(--cometchat-background-color-01);
}

.oval-animation circle {
    stroke: var(--cometchat-background-color-01);
}