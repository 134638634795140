.cometchat-call-log-history {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cometchat-call-log-history .cometchat-list-item__leading-view,
.cometchat-call-log-history .cometchat-avatar,
.cometchat-call-log-history .cometchat-avatar__image {
    height: 16px;
    width: 16px;
    background: transparent;
    border-radius: 0px;
}

.cometchat-call-log-history__subtitle .cometchat-date {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-overflow: ellipsis;
    font: var(--cometchat-font-body-regular);
    padding: 0px;
}

.cometchat-call-log-history .cometchat-call-log-history__tail-view {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-history .cometchat-call-log-history__tail-view-disabled {
    overflow: hidden;
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-medium);
}

.cometchat-call-log-history .cometchat-list-item__tail-view {
    width: fit-content;
}