.cometchat-empty-state-view {
    display: flex;
    width: 316px;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-5, 20px);
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-empty-state-view__icon {
    width: 120px;
    height: 120px;
    -webkit-mask: url("../../assets/emptyStateIconChat.svg") center center no-repeat;
    background: var(--cometchat-neutral-color-400, #DCDCDC);
    -webkit-mask-size: contain;
}

.cometchat-empty-state-view__icon-call {
    width: 120px;
    height: 120px;
    -webkit-mask: url("../../assets/call_empty.svg") center center no-repeat;
    background: var(--cometchat-neutral-color-400, #DCDCDC);
    -webkit-mask-size: contain;
}

.cometchat-empty-state-view__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    width: 280px;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.cometchat-empty-state-view__text-title {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading3-bold, 700 18px Roboto);
}

@media (max-width: 768px) {
    .cometchat-empty-state-view {
        display: none;
    }
}