.join-group-password__wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #141414;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
}

.join-group-password {
    display: flex;
    width: 400px;
    padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-10, 40px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-10, 40px);
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.join-group-password__header {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    display: flex;
    width: 100%;
    font: var(--cometchat-font-heading1-bold, 700 24px Roboto);
}

.join-group-password__header-title {
    width: 100%;
}

.join-group-password__header-close {
    -webkit-mask: url("../../assets/close.svg") center center no-repeat;
    background: var(--cometchat-icon-color-primary, #141414);
    -webkit-mask-size: contain;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;
}

.join-group-password__header-close:hover {
    background: var(--cometchat-icon-color-highlight);
}

.join-group-password__content {
    display: flex;
    min-width: 240px;
    max-width: 1440px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-4, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-3, 12px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-background-color-01, #FFF);
}

.join-group-password__content-avatar .cometchat-avatar {
    width: 60px;
    height: 60px;
}

.join-group-password__content-avatar .cometchat-avatar__text {
    font: var(--cometchat-font-heading1-bold, 700 24px Roboto);
}

.join-group-password__content-text {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
}

.join-group-password__content-text-title {
    overflow: hidden;
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    text-overflow: ellipsis;
    font: var(--cometchat-font-heading4-medium, 500 16px Roboto);
}

.join-group-password__content-text-subtitle {
    overflow: hidden;
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.join-group-password__input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
}

.join-group-password__input-label {
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-caption1-medium, 500 12px Roboto);
}

.join-group-password__input input {
    display: flex;
    height: 36px;
    padding: var(--cometchat-padding-2, 8px);
    color: var(--cometchat-text-color-primary, #141414);
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-02, #FAFAFA);
}

.join-group-password__input input:focus-visible {
    outline: none;
}

.join-group-password__input input::placeholder {
    color: var(--cometchat-text-color-tertiary, #A1A1A1);
    font: var(--cometchat-font-body-regular, 400 14px Roboto);
}

.join-group-password__button,
.join-group-password__button .cometchat-button {
    width: 100%;
}

.join-group-password__input-wrong {
    color: var(--cometchat-error-color, #F44649);
    font: var(--cometchat-font-caption1-regular, 400 12px Roboto);
}

.selector-wrapper .cometchat-conversations .cometchat-list__header-menu .cometchat-button__icon {
    background: var(--cometchat-icon-color-primary);
}

.cometchat-conversations .cometchat-list__header-menu .cometchat-button__icon:hover {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-list__header-search-bar {
    border-right: none;
}

.cometchat .cometchat-menu-list__sub-menu-list-item {
    text-align: left;
}

.cometchat .cometchat-conversations .cometchat-menu-list__sub-menu-list {
    width: 212px;
    top: 40px !important;
    left: 172px !important;
}

#logged-in-user {
    border-bottom: 2px solid var(--cometchat-border-color-default, #E8E8E8);
}

#logged-in-user .cometchat-menu-list__sub-menu-item-title ,
#logged-in-user .cometchat-menu-list__sub-menu-list-item {
    cursor: default;
}


.cometchat-menu-list__sub-menu-list-item-icon-log-out{
    background-color: var( --cometchat-error-color,#F44649);
}

.cometchat-menu-list__sub-menu-item-title-log-out {
    color:var( --cometchat-error-color,#F44649)
}

.chat-menu .cometchat .cometchat-menu-list__sub-menu-item-title {
 cursor: pointer;
}

.chat-menu .cometchat .cometchat-menu-list__sub-menu {
    box-shadow: none;
}

.chat-menu .cometchat .cometchat-menu-list__sub-menu-icon {
    background-color: var(--cometchat-icon-color-primary,#141414);
    width: 24px;
    height: 24px;
}

@media (max-width: 768px) {
    .join-group-password {
        display: flex;
        width: 320px;
        padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-5, 20px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-5, 20px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--cometchat-padding-5, 20px);
        border-radius: var(--cometchat-radius-5, 20px);
        border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
        background: var(--cometchat-background-color-01, #FFF);
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }
}